<template>
   <div class="vr-iframe-wrapper wrapper-scroll-view">
       <div class="cover flex-box-column">
           <div class="hd">
                <van-nav-bar left-arrow :title="houseName || '楼盘详情'" @click-left="vrBack"></van-nav-bar>
           </div>
           <div class="bd">
               <div class="loading-tips" v-if="!buildingVr && !pageError">正在加载...</div>
               <iframe v-if="buildingVr && !pageError" :src="buildingVr" frameborder="0"></iframe>
               <van-empty image="error" description="加载失败，请点击重试" v-if="pageError" @click.native="getHouseData" />
           </div>
       </div>
   </div>
</template>

<script>
import backMixin from "@/mixins/back";
import api from "@/api";
import wxConfig from "@/utils/wxconfig";
export default {

    mixins:[backMixin],

    data(){

        return{

            houseName:'',

            pageError:false,

            buildingVr:''

        }

    },

    methods:{

        getHouseData(){

            this.$toast.loading({

                message:'加载中...',

                duration:0,

                overlay:true

            });


            api.getHouseData({

                options:{

                    id:this.$route.params.id

                },

                success:(res)=>{

                    if(res.type==200){

                        if(res.data.buildingVr){

                            this.buildingVr = res.data.buildingVr;

                            this.houseData = res.data;

                            this.houseName = res.data.name;

                            this.pageError=false;

                            if(this.$store.state.userInfo.ready){

                                this.wxInit();

                            }

                        }else{

                            this.pageError= true;

                        }

                        
                        
                    }else{

                        this.$dialog.alert({

                            title:'获取vr数据失败',

                            message:res.msg || '获取对应的楼盘vr数据失败，请稍后重试'

                        }).then(()=>{});

                        this.pageError=true;

                    }

                },

                error:(err)=>{

                    console.log(err);

                    this.$dialog.alert({

                        title:'获取数据失败',

                        message:'获取对应的楼盘vr数据失败,请求异常，请稍后重试'

                    }).then(()=>{});

                    this.pageError=true;
                    

                },

                complete:()=>{

                    this.$toast.clear();

                }

            })

        },

        wxInit(){

            wxConfig.init().then(wx=>{

                wx.updateAppMessageShareData({ 

                    title: this.houseData?.name+'-vr看房', // 分享标题

                    desc: this.houseData?.projectIntro || '', // 分享描述

                    // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致

                    link: 'https://'+window.location.host+process.env.VUE_APP_SYSTEM_ROUTER_BASE+this.$route.path+`?shareUser=${this.$store.state.userInfo.uid}`, 

                    imgUrl: this.houseData?.logo, // 分享图标

                    success: ()=> {}

                });

                wx.onMenuShareTimeline({

                    title: this.houseData?.name, // 分享标题

                    desc: this.houseData?.projectIntro || '', // 分享描述

                    // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致

                    link: 'https://'+window.location.host+process.env.VUE_APP_SYSTEM_ROUTER_BASE+this.$route.path+`?shareUser=${this.$store.state.userInfo.uid}`, 

                    imgUrl: this.houseData?.logo, // 分享图标
                    success:()=>{}
                    
                });

            }).catch(err=>{

                console.log(err);

            })


        },

        
        //后退特殊化

        vrBack(){

            if(this.$route.query?.shareUser){

                this.$router.replace({path:`/house/detail/${this.$route.params.id}`,query:{shareUser:this.$route.query.shareUser}});

            }else{

                this.back();

            }


        }

    },


    created(){

        this.houseName = this.$route.query.name ? decodeURI(this.$route.query.name) : '';

        this.$nextTick(()=>{

            this.getHouseData();

        })

    }

}
</script>

<style scoped lang="less">
.vr-iframe-wrapper{


    .bd{

        position: relative;

        iframe{

            width: 100%;

            height:100%;

        }

    }

}
</style>
